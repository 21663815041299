.field {
    width: 300px;
    border-radius: 25px;
}

::-webkit-input-placeholder {
text-align: center;
}

:-moz-placeholder {
text-align: center;
}

#time_related_controls_password, #time_related_controls_email {
    border-radius: 0px !important;
}

