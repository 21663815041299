.Content {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.basic-background {
  background-color: #fafafa;
}

.Title-Bar {
  text-align: center;
  padding-bottom: 30px;
}

.ant-input {
  border-radius: 25px;
}

.ant-picker {
  border-radius: 25px;
}

.ant-btn {
  border-radius: 25px;
}

.App {
  height: 100vh;
}

.align-medium {
  transform: translateY(50%);
  
}

.amplify-tabs {
  display: none !important;
}

.data-amplify-authenticator{
  transform: translate(50%, 50%);
}

.ant-select-selector, .ant-select-dropdown, .ant-picker-panel-container {
  border-radius: 25px !important;
}